<template>
	<div v-show="!showModalsnack && !showModal && !showEditModal">
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<a-row>
<!--					<a-form-item class="ui-form__item" name="organizationId" label="影院组织">-->
<!--						<a-select placeholder="请选择" v-model:value="formState.organizationId" style="width: 250px;" @change="(id) => getAllCinemaList(id, true)">-->
<!--							&lt;!&ndash; <a-select-option :value="0">全部</a-select-option> &ndash;&gt;-->
<!--							<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>-->
<!--						</a-select>-->
<!--					</a-form-item>-->
					
<!--					<a-form-item class="ui-form__item" name="cinemaId" label="所属影院">-->
<!--						<a-select placeholder="请选择" v-model:value="formState.cinemaId" style="width: 250px;">-->
<!--							<a-select-option :value="0">全部</a-select-option>-->
<!--							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>-->
<!--						</a-select>-->
<!--					</a-form-item>-->
          <a-form-item label="是否一券通" name="isOneVoucher" class="ui-form__item">
            <a-select v-model:value="formState.isOneVoucher" style="width: 180px;" placeholder="请选择状态">
              <a-select-option :value="''">全部</a-select-option>
              <a-select-option :value="1">是</a-select-option>
              <a-select-option :value="0">否</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item class="ui-form__item" name="useCinemaId" label="可用影院">
            <a-select placeholder="请选择" v-model:value="formState.useCinemaId" style="width: 250px;">
              <a-select-option :value="''">全部</a-select-option>
              <a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
            </a-select>
          </a-form-item>
					
					<a-form-item label="批次号" name="batchNo" class="ui-form__item">
						<a-input v-model:value="formState.batchNo" placeholder="请输入批次号"></a-input>
					</a-form-item>

					<a-form-item label="批次号名称" name="name" class="ui-form__item">
						<a-input v-model:value="formState.name" placeholder="请输入批次名称"></a-input>
					</a-form-item>
					
					<a-form-item label="表格名称" name="title" class="ui-form__item">
						<a-input v-model:value="formState.title" placeholder="请输入表格名称"></a-input>
					</a-form-item>
					
					<a-form-item label="创建类型" name="isImport" class="ui-form__item">
						<a-select v-model:value="formState.isImport" placeholder="请选择创建类型" style="width: 190px;">
							<a-select-option :value="-1">全部</a-select-option>
							<a-select-option :value="0">新增</a-select-option>
							<a-select-option :value="1">导入</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="是否用于商品卖品发放" name="isGrant" class="ui-form__item">
						<a-select v-model:value="formState.isGrant" placeholder="请选择是否用于商品卖品发放" style="width: 190px;">
							<a-select-option :value="''">全部</a-select-option>
							<a-select-option :value="1">是</a-select-option>
							<a-select-option :value="0">否</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="有效期开始时间" class="ui-form__item">
						<a-range-picker v-model:value="time"></a-range-picker>
					</a-form-item>

					<a-form-item label="有效期结束时间" class="ui-form__item">
						<a-range-picker v-model:value="time1"></a-range-picker>
					</a-form-item>
				</a-row>
				
				<a-row>
					<a-col :span="18">
						<span v-if="pageType" v-permission="['coupon_snack_recordInfo_set']" style="margin-right: 20px;">
							<userSelect :pageType="2" @change="getData"></userSelect>
						</span>
						<a-button type="primary" @click="onAdd" style="margin-right: 20px;" v-permission="[pageType ? 'coupon_snack_recordInfo_add' : 'coupon_snack_record_add']">新增卖品券</a-button>
						<a-button type="primary" @click="onImportModal" v-permission="[pageType ? 'coupon_snack_recordInfo_import' : 'coupon_snack_record_import']">导入</a-button>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>			
			
			<div style="margin-top: 20px;">
				<a-table rowKey="id" :pagination="pagination" :columns="columns" :dataSource="list" :scroll="{ x: 1900 }">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'tableName'">
							{{ record.title || '--' }}
						</template>
						
						<template v-if="column.key === 'type'">
							{{ record.isImport ? '导入' : '新增' }}
						</template>

						<template v-if="column.key === 'isGrant'">
							{{ record.isGrant ? '是' : '否' }}
						</template>
            <template v-if="column.key === 'isOneVoucher'">
              {{ record.isOneVoucher ? '是' : '否' }}
            </template>
            <template v-if="column.key === 'cinemaNames'">
              {{ record.isOneVoucher == 1 ? '全部影院': record.cinemaNames }}
            </template>

						<template v-if="column.key === 'createdTime'">
							{{ transDateTime(record.createTime) }}
						</template>
						
						<template v-if="column.key === 'time'">
							<div v-if="record.validPeriodType === 7">
								领取后{{ record.period }}天内有效
							</div>
							<div v-else>
								{{ transDateTime(record.receiveStartTime, 1) }} - {{ transDateTime(record.receiveEndTime, 1) }}
							</div>
						</template>
						
						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
								</a-button>
								<template #overlay>
									<a-menu>
										<div @click="onSee(record)">
											<a-menu-item>查看</a-menu-item>
										</div>
										<div v-permission="[pageType ? 'coupon_snack_recordInfo_edit' : 'coupon_snack_record_edit']" @click="onEdit(record)">
											<a-menu-item>修改</a-menu-item>
										</div>
										<div v-permission="[pageType ? 'coupon_snack_recordInfo_detail' : 'coupon_snack_record_detail']" @click="onDetail(record)">
											<a-menu-item>
												查看批次号详情
											</a-menu-item>
										</div>
                    <div v-permission="[pageType ? 'coupon_snack_recordInfo_snackSet' : 'coupon_snack_record_snackSet']" @click="onSnack(record)">
                      <a-menu-item>
                        查看卖品配置
                      </a-menu-item>
                    </div>
										<div v-permission="[pageType ? 'coupon_snack_recordInfo_importRecord' : 'coupon_snack_record_importRecord']" @click="onImportRecord(record)">
											<a-menu-item>导入卖品券</a-menu-item>
										</div>
										<div v-permission="[pageType ? 'coupon_snack_recordInfo_export' : 'coupon_snack_record_export']">
											<a-menu-item>
												<!-- 导出 -->
												<exportComponent type="snackCoupon" :searchData="{ couponType: 3, voucherImportId: record.id }">
													<div>导出</div>
												</exportComponent>
											</a-menu-item>
										</div>
										<div v-permission="[pageType ? 'coupon_snack_recordInfo_delete' : 'coupon_snack_record_delete']" @click="onDelete(record)">
											<a-menu-item>
												删除
											</a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
				</a-table>
			</div>
			
			<a-modal v-model:visible="importVisible" title="导入卖品券" width="850px" @ok="onImportCard">
				<a-spin :spinning="loading">
					<a-form ref="importFormRef" name="importFormRef" scrollToFirstError :model="importModelRef"
						:labelCol="{ span: 4 }" :wrapperCol="{ span: 20 }">
						
						<a-form-item v-if="!importId" name="organizationId" label="影院组织" :rules="[{ required: true, message: '必选项不允许为空' }]">
							<a-select placeholder="请选择" v-model:value="importModelRef.organizationId" @change="(id) => getAllCinemaList(id, false)">
								<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
							</a-select>
						</a-form-item>
						
						<a-form-item v-if="!importId" name="cinemaId" label="所属影院" :rules="[{ required: true, message: '必选项不允许为空' }]">
							<a-select placeholder="请选择" v-model:value="importModelRef.cinemaId" @change="onSnackClear">
								<a-select-option v-for="item in cinemaList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
							</a-select>
						</a-form-item>
						
						<a-form-item v-if="!importId" label="批次号" name="batchNo" :rules="[{ required: true, message: '必填项不允许为空' }]">
							<a-input v-model:value="importModelRef.batchNo" placeholder="请输入" />
						</a-form-item>

						<a-form-item v-if="!importId" label="批次号名称" name="name" :rules="[{ required: true, message: '必填项不允许为空' }]">
							<a-input v-model:value="importModelRef.name" placeholder="请输入" />
						</a-form-item>

						<a-form-item v-if="!importId" label="有效期" name='time' :rules="[{required: true, message: '必填项不允许为空'}]">
							<a-range-picker v-model:value="importModelRef.time"/>
						</a-form-item>

						<a-form-item v-if="!importId" label="是否用于商品卖品发放" name="isGrant" :labelCol="{ span: 5 }" :wrapperCol="{ span: 19 }">
							<a-radio-group v-model:value="importModelRef.isGrant">
								<a-radio :value="1">是</a-radio>
								<a-radio :value="0">否</a-radio>
							</a-radio-group>
						</a-form-item>
						
						<a-form-item v-if="!importId" label="可兑换卖品">
							<snackModal v-if="importModelRef.cinemaId" v-model:value="snackIds" :selectItem="snackList" :id="importModelRef.cinemaId" @change="onSnackChange"></snackModal>
							<a-button v-else disabled>请选择所属影院</a-button>
							
							<a-form-item-rest v-if="snackList.length">
								<a-table :dataSource="snackList" :columns="snackColumns" rowKey="id" :pagination="false">
									<template #bodyCell="{ column, record, index }">
										<template v-if="column.key === 'img'">
											<a-image style="width: 40px;" :src="record.imgUrl"></a-image>
										</template>
										<template v-if="column.key === 'card'">
											<div v-if="record.isCard">
												<a-tag color="orange">{{ ['次卡', '年卡'][record.cardType - 1] }}</a-tag>
												<div>卡次数：{{ record.cardBalanceTimes }}</div>
												<div>卡等级：{{ record.cardTitle || '' }}</div>
											</div>
											<div v-else>--</div>
										</template>
										<template v-if="column.key === 'price'">
											<div>销售价：{{ record.nowPrice }}</div>
											<div>成本价：{{ record.costPrice }}</div>
											<div>市场价：{{ record.originPrice }}</div>
										</template>
										<template v-if="column.key === 'action'">
											<a-button type="link" @click="onSnackDel(record, index)">删除</a-button>
										</template>
									</template>
								</a-table>
							</a-form-item-rest>
						</a-form-item>
						
						<a-form-item label="点击下载">
							<!-- @click="onDownload" -->
							<a href="/dashboard/导入卖品券模板.xlsx">Excel模板</a>
						</a-form-item>
						
						<a-form-item label="选择文件">
							<a-upload v-model:file-list="fileList" list-type="picture" action="/admin/ajaxUpload.do"
								accept=".xlsx" :max-count="1" :beforeUpload="beforeUpload" @customRequest="() => {}">
								<a-button>
									<Icon icon="UploadOutlined"></Icon>
									上传
								</a-button>
							</a-upload>
							<p style="margin-top: 10px;color:red;">
								注：请下载模板输入信息，填写完成后保存，然后选择表格上传 {{ !importId ? '，批量导入仅可导入一个批次' : '' }}
							</p>
						</a-form-item>
						
						<a-form-item v-if="!importId" label="使用规则" name="useRule" :rules="[{required: true, message: '必填项不允许为空'}]">
							<a-textarea v-model:value="importModelRef.useRule" placeholder="请输入使用规则,换行需要输入'|'字符"></a-textarea>
						</a-form-item>
					</a-form>
				</a-spin>
			</a-modal>
		</a-spin>
	</div>
	<detail v-if="showModal" :id="id" @back="onBack"></detail>

  <snack v-if="showModalsnack" :id="id" @back="onBack" :snackCinemaIds = "cinemaIds"></snack>


	<temp v-if="showEditModal" :id="id" :isSee="isSee" :isEdit="isEdit" @back="onBack"></temp>

</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import snackModal from '@/components/snackSelect/index.vue';
	import detail from './detail.vue';
  import snack from './snack.vue'
	import temp from '@/views/coupon/snack/list/temp.vue';
	import exportComponent from '@/components/exportReport/exportReport.vue';
	import userSelect from '@/components/selectUser/index.vue';
	import { getOrganizationList, getCinemaList } from '@/service/modules/cinema.js';
	import { getImportRecord, deleteImportRecord, delAndSync, importSaleCoupon, importRecordExcel } from '@/service/modules/coupon.js';
	export default {
		components: {Icon, snackModal, detail,snack, temp, exportComponent, userSelect },
		data() {
			return {
				loading: false,
				organizationList: [],
				cinemaAllList: [],
				formState: {
					// organizationId: 0,
					cinemaId: 0,
					isImport: -1,
					isGrant: '',
          isOneVoucher:'',
          useCinemaId: '',
				},
				time:[],
				time1: [],
				cinemaList:[],
				searchData: {},
				showModal: false,
        showModalsnack:false,
				modelRef: {
					time: []
				},
				fileList: [],
				list: [],
				columns: [
        //   {
				// 	title: '所属影院',
				// 	dataIndex: 'cinemaName'
				// },
          {
					title: '表格名称',
					key: 'tableName'
				},{
					title: '批次号',
					dataIndex: 'batchNo'
				}, {
					title: '批次号名称',
					dataIndex: 'name'
				}, {
          title: '是否一券通',
          key: 'isOneVoucher'
        }, {
          title: '可用影院',
          key: 'cinemaNames',
          width: 200,
        },{
					title: '类型',
					key: 'type'
				}, {
					title: '有效期',
					key: 'time'
				},{
					title: '卖品券数量',
					dataIndex: 'importCount',
					width: 150
				}, {
					title: '已绑定数量',
					dataIndex: 'bindCount',
					width: 150
				}, {
					title: '是否用于商品卖品发放',
					key: 'isGrant'
				}, {
					title: '操作人员',
					dataIndex: 'operationUserName'
				}, {
					title: '创建时间',
					key: 'createdTime',
					width: 200
				}, {
					title: '操作',
					fixed: 'right',
					key: 'action',
					width: 150
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				showEditModal: false,
				isEdit: false,
				isSee: false,
				id: 0,
				
				importVisible: false,
				importModelRef: {
					isGrant: 0
				},
				snackList: [],
				snackColumns:  [{
					title: '卖品名称',
					dataIndex: 'mainTitle'
				}, {
					title: '卖品图片',
					key: 'img'
				}, {
					title: '卡信息',
					key: 'card'
				}, {
					title: '价格信息',
					key: 'price'
				}, {
					title: '操作',
					key: 'action',
					width: 100
				}],
				snackIds: [],
				pageType: 0,
        cinemaIds: []
			}
		},
		created() {
			if (this.$router.currentRoute.value.path === '/coupon/snack/record') {
				this.pageType = 0;
			} else if (this.$router.currentRoute.value.path === '/coupon/snack/recordInfo') {
				this.pageType = 1;
				this.columns.splice(9, 1);
			}
			this.getOrganizationList();
			//this.onSearch();
		},
		methods: {
			onBack(isRef) {
				this.showEditModal = false;
				this.showModal = false;
        this.showModalsnack = false;
				if(isRef) {
					this.getData();
				}
			},
			onSearch() {
				this.pagination.current = 1;
				// //this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.searchData.pageType = this.pageType ? this.pageType : undefined;
				// this.searchData.organizationId = this.searchData.organizationId ? this.searchData.organizationId : undefined;
        //去掉organizationId
        delete this.searchData.organizationId
        this.searchData.cinemaId = this.searchData.cinemaId ? this.searchData.cinemaId : undefined;
				this.searchData.isImport = this.searchData.isImport !== -1 ? this.searchData.isImport : undefined;
				this.searchData.isGrant = this.searchData.isGrant !== '' ? this.searchData.isGrant : undefined;

        this.searchData.isOneVoucher = this.searchData.isOneVoucher !== '' ? this.searchData.isOneVoucher : undefined;
        this.searchData.useCinemaId = this.searchData.useCinemaId !== ''? this.searchData.useCinemaId : undefined;

        if (this.time && this.time.length) {
					this.searchData.validStartTime = this.time[0].startOf('day').unix();
					this.searchData.validEndTime = this.time[1].endOf('day').unix();
				}
				if (this.time1 && this.time1.length) {
					this.searchData.failureStartTime = this.time1[0].startOf('day').unix();
					this.searchData.failureEndTime = this.time1[1].endOf('day').unix();
				}
				this.getData();
			},
			reset() {
				this.$refs.form.resetFields();
				if (this.organizationList.length) {
					this.formState.organizationId = this.organizationList[0].id;
					this.getAllCinemaList(this.formState.organizationId, true);
				}
				this.time = [];
				this.time1 = [];
				this.onSearch();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getImportRecord({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						couponType: 3,
						...this.searchData
					})
					this.loading = false;
					if (ret.code === 200) {
						this.list = ret.data.list;
						this.pagination.total = ret.data.totalCount;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getOrganizationList() {
				this.loading = true;
				try {
					let ret = await getOrganizationList({
						page: 1,
						pageSize: 999999
					});
					if (ret.code === 200) {
						this.organizationList = ret.data.list;
						if (this.organizationList.length) {
							this.formState.organizationId = this.organizationList[0].id;
							this.getAllCinemaList(this.formState.organizationId, true);
						}
						this.onSearch();
					} else {
						this.loading = false;
					}
				} catch(e) {
					this.loading = false;
				}

			},
			async getAllCinemaList(organizationId, isSearch) {
				if (!isSearch) {
					this.cinemaList = [];
					this.importModelRef.cinemaId = undefined;
					this.onSnackClear();
				} else {
					this.cinemaAllList = [];
					this.formState.cinemaId = 0;
          this.formState.useCinemaId = '';
				}
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					if (!isSearch) {
						this.cinemaList = ret.data.list;
					} else {
						this.cinemaAllList = ret.data.list;
					}
				}
			},
			onAdd() {
				this.id = 0;
				this.isEdit = false;
				this.isSee = false;
				this.showEditModal = true;
			},
			onEdit(item) {
				this.id = item.id;
				this.isEdit = true;
				this.isSee = false;
				this.showEditModal = true;
			},
			onSee(item) {
				this.id = item.id;
				this.isEdit = false;
				this.isSee = true;
				this.showEditModal = true;
			},
			onSubmit() {
				this.$refs.addForm.validateFields().then(() => {
					
				})
			},
			onDelete(item) {
				this.$confirm({
					title: '提示',
					content: '确定删除吗？删除后该批次下的所有卖品券也会删除',
					onOk: async ()=> {
						this.loading = true;
						try {
							let ret = await delAndSync({
								id: item.id
							});
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('操作成功');
								this.getData();
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			},
			onSnackClear() {
				this.snackIds = [];
				this.snackList = [];
			},
			onSnackChange(list) {
				this.snackList = JSON.parse(JSON.stringify(list));
        console.log(this.snackList,"this.snackList")
			},
			onSnackDel(item, index) {
				let i = this.snackIds.indexOf(item.id);
				if (i !== -1) {
					this.snackIds.splice(i, 1);
				}
				this.snackList.splice(index, 1);
			},
			onImportRecord(record) {
				this.fileList = [];
				this.snackIds = [];
				this.snackList = [];
				this.importId = record.id;
				this.importVisible = true;
				this.$nextTick(() => {
					this.$refs.importFormRef.resetFields();
				})
			},
			onImportModal() {
				this.importId = 0;
				this.fileList = [];
				this.snackIds = [];
				this.snackList = [];
				this.importVisible = true;
				this.$nextTick(() => {
					this.$refs.importFormRef.resetFields();
				})
			},
			onImportCard() {
				if (this.fileList.length === 0) {
					return this.$message.warn('请上传文件');
				}
				const isLt1M = this.fileList[0].size / 1024 / 1024 < 1;
				if (!isLt1M) {
					this.$message.error('上传文件太大了, 最大只允许1M');
				}

				if(this.importId === 0 && this.snackList.length === 0) {
					return this.$message.warn('请选择可兑换卖品');
				}
				this.$refs.importFormRef.validateFields().then(async () => {
					let postData = {};
					if (!this.importId) {
						postData = this.$deepClone(this.importModelRef)
						postData.goodsIds = this.snackList.map(item => item.id).join(',');
						postData.receiveStartTime = this.moment(postData.time[0]).startOf('day').unix();
						postData.receiveEndTime = this.moment(postData.time[1]).endOf('day').unix();
					} else {
						postData.id = this.importId;
					}
					this.loading = true;
					try {
						let ret;
						if (!this.importId) {
							ret = await importSaleCoupon(postData, this.fileList[0]);
						} else {
							ret = await importRecordExcel(postData, this.fileList[0]);
						}
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('导入任务创建成功，请前往【卡券-任务中心-导入任务列表】查看进度详情');
							this.$refs.importFormRef.resetFields();
							this.fileList = [];
							this.snackList = [];
							this.snackIds = [];
							this.importVisible = false;
							this.getData();
						}
					} catch(e) {
						this.loading = false;
					}
				})
			},
			onDetail(item) {
				this.id = item.id;
				this.showModal = !this.showModal
			},
      onSnack(item) {
        this.id = item.id;
        console.log(item)
        this.showModalsnack = !this.showModalsnack
        this.cinemaIds = item.cinemaIds ? item.cinemaIds.split(',') : []
      },


			beforeUpload(file) {
				const isLt1M = file.size / 1024 / 1024 < 1;
				if (!isLt1M) {
					this.$message.error('上传文件太大了, 最大只允许1M');
				}
				return false;
			},
      // onChangeIsOne(value){
      //   if (value == ''){
      //     this.formState.useCinemaId = ''
      //   }
      //
      // }
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>
